import { userEnum, msgTypeEnum } from './chatbot.enums';
import { v4 as uuid } from 'uuid';

export interface IMessageObj {
    sessionId: string; //sessionId
    messageId: string; //messageId
    senderId: string; //senderId
    userType: userEnum;
    timestamp: number;
    messageType: msgTypeEnum;
    language: string
  }
  
export abstract class Messages implements IMessageObj {
    readonly sessionId: string; //sessionId
    readonly messageId: string; //messageId
    readonly senderId: string; //senderId
    readonly userType: userEnum;
    readonly timestamp: number;
    readonly messageType: msgTypeEnum;
    readonly language: string;

    constructor(sessionId: string, senderId: string, userType: userEnum, messageType: msgTypeEnum) {
        this.sessionId = sessionId;
        this.senderId = senderId;
        this.messageId = uuid();
        this.userType = userType;
        this.messageType = messageType;
        this.timestamp = Math.round(Date.now() / 1000);
        this.language = localStorage.getItem("language") || "en"

    }
}

export class TextMessage extends Messages {
    public text: string;
    public textLength: number;
    constructor(sessionId: string, senderId: string, userType: userEnum, content: any) {
        super(sessionId, senderId, userType, msgTypeEnum.text);
        this.text = content;

        var regex = /(<([^>]+)>)/ig; 
        let ftext: string =  content.replace(regex, "");
        this.textLength = ftext.length;
    
    }
}

export class QuickRepliesMessage extends Messages {
    public quickReplies: Array<string>;
    constructor(sessionId: string, senderId: string, userType: userEnum, content: any) {
        super(sessionId, senderId, userType, msgTypeEnum.quickReplies);
        this.quickReplies = content;
    }
}

export class InfoMessage extends Messages {
    public text: Array<string>;
    public textLength: number;
    constructor(sessionId: string, senderId: string, userType: userEnum, content: any) {
        super(sessionId, senderId, userType, msgTypeEnum.info);
        this.text = content;
        var regex = /(<([^>]+)>)/ig; 
        let ftext: string =  content.replace(regex, "");
        this.textLength = ftext.length;
    }
}

export class ImageMessage extends Messages {
    public imgPath: string;
    constructor(sessionId: string, senderId: string, userType: userEnum, content: any) {
        super(sessionId, senderId, userType, msgTypeEnum.image);
        this.imgPath = content;
    }
}
