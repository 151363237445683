import ChatboxProvider from './chatbot.provider';
import ChatbotStateModel from '../service/chatbotStateModel';
import { userEnum, dialogAction } from './chatbot.enums';
import { InfoMessage, TextMessage, QuickRepliesMessage, ImageMessage, IMessageObj } from './chatbot.messages';

class ChatboxService {

  async setupChatSession() {
    const result = await ChatboxProvider.postMessage(
      this.createMessage({ info: "hi" }, userEnum.bot));
    return result.data.map((msg: any) => { return this.createMessage(msg, userEnum.bot); });
  }

  createMessage(msg: any, userType: userEnum) {
    if (msg.text) {
      return new TextMessage(ChatbotStateModel.sessionId, ChatbotStateModel.senderId, userType, msg.text.text[0])
    } else if (msg.quickReplies) {
      return new QuickRepliesMessage(ChatbotStateModel.sessionId, ChatbotStateModel.senderId, userType, msg.quickReplies.quickReplies)
    } else if (msg.info) {
      return new InfoMessage(ChatbotStateModel.sessionId, ChatbotStateModel.senderId, userType, msg.info)
    } else if (msg.image) {
       return new ImageMessage(ChatbotStateModel.sessionId, ChatbotStateModel.senderId, userType, msg.image.imageUri)
    } else {
      return new TextMessage(ChatbotStateModel.sessionId, ChatbotStateModel.senderId, userType, msg);
    }
  }

  async postMessage(msgObj: IMessageObj) {
    const result = await ChatboxProvider.postMessage(msgObj);
    return result.data.map((msg: any) => { return this.createMessage(msg, userEnum.bot); });
  }

  async getAutoCompleteStrings() {
    return ["Toll Fares", "Traffic Info", "PLUSMiles", "Product & Facilities",
      "PLUSMiles Log in or Registration", "e-Statement", "PLUSMiles points",
      "Forgot password", "Fail to receive email verification", "Touch 'n Go Reload Location",
      "PLUSTrack", "About PLUSRonda", "Nearest Petrol Station",
      "Nearest Rest and Service Area"];
  }

  async getTollPlazaSuggestion() {
     let result = await ChatboxProvider.getSuggestion(dialogAction.TollPlazaSuggestion);
     return result.data;
  }
}

export default new ChatboxService();