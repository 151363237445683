export enum userEnum {
  user = "user",
  bot = "bot"
}
  
export enum msgTypeEnum {
  text = "text",
  quickReplies = "quickReplies",
  info = "info",
  image = "image"
}

export enum dialogAction {
  TollPlazaSuggestion = "[#TPS]",
  GetLocation = "[#GLC]",
  ChangeEnglish = "[#C_EN]",
  ChangeMalay = "[#C_MS]",

}


