
import {IMessageObj} from './chatbot.messages'
import { v4 as uuid } from 'uuid';

class ChatbotStateModel {
    public chatlist: Array<IMessageObj>;
    readonly sessionId: string;
    readonly senderId: string;

    constructor() {
        this.chatlist = [];
        this.sessionId = uuid();
        this.senderId = uuid();
    }
}

export default new ChatbotStateModel();