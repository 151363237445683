import axios from 'axios'

class ChatboxProvider {
    api_path: any = process.env.VUE_APP_MESSAGE_ROUTER_URL;
    api_key: any = process.env.VUE_APP_MESSAGE_ROUTER_KEY;
    constructor() {}
     
    public async postMessage(messageObj: any) {
      const headers  = {
        'Content-Type': 'application/json',
        'bg-key': this.api_key,
        'language': localStorage.getItem("language") || "en"
      }
      return await axios.post(`${this.api_path}/message/webhook`, JSON.stringify(messageObj), {headers});
    }

    public async getSuggestion(topic: string) {
      const headers  = {
        'Content-Type': 'application/json',
        'bg-key': this.api_key,
      }
      const body = {
        topic
      }
      return await axios.post(`${this.api_path}/message/webhook?t=s` , JSON.stringify(body), {headers});
    }
}

export default new ChatboxProvider();
