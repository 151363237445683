import { Component, Vue,Prop } from 'vue-property-decorator';
import ChatbotStateModel from '../../service/chatbotStateModel';
import ChatbotService from '../../service/chatbot.service';
import { userEnum, msgTypeEnum, dialogAction } from '../../service/chatbot.enums';
import chatbotService from '../../service/chatbot.service';

@Component
export default class Chatbot extends Vue {
    @Prop({type: String, default: 'false'}) private isapp!: string;

    private isChatOpen = false;
    private chatbotState: any = ChatbotStateModel;
    private userMessage: string = "";
    private isLoading: boolean = false;
    private suggestionList: Array<any> = [];
    private tollPlazaSuggestionList: Array<any> = [];
    private suggestionListDisplay?: Array<any> = [];
    private readMoreIndex: Array<number> = []
    private chatbotName: string = process.env.VUE_APP_CHATBOT_NAME || 'Chatbot';
    private screensize: number = 0;
    private currentlocation: string = "";
    private hideCloseButton: boolean = false;

    mounted() {
        
        if (this.isapp && this.isapp === 'true') {
           this.openChat();
           this.hideCloseButton = true;
        }
    }

    updated(){
        let appWin = this.getInputElement('putri');
        if (appWin)
        this.screensize = appWin.offsetWidth;
    }


    async refreshMessages(results: any) {
        this.suggestionList = [];
        const lastItem = this.chatbotState.chatlist[this.chatbotState.chatlist.length -1];
        if (lastItem && lastItem.messageType === msgTypeEnum.quickReplies ) {
            this.chatbotState.chatlist.pop(); //remove old   quickreplies
        }
        results.forEach(async (msg: any) => { 
            if (msg.text === dialogAction.TollPlazaSuggestion ) {
                this.suggestionList = this.tollPlazaSuggestionList;
            } else  if (msg.text === dialogAction.GetLocation ) {
                this.shareLocation();
            } else  if (msg.text === dialogAction.ChangeEnglish ) {
                localStorage.setItem("language", "en");
            } else  if (msg.text === dialogAction.ChangeMalay ) {
                localStorage.setItem("language", "ms");
            } else {
            this.chatbotState.chatlist.push(msg); 
            }
        });
        this.scrollToBottom();
    }

    async sendReply(message: string) {
        this.isLoading = true;
        const msgObj = ChatbotService.createMessage(message, userEnum.user);
        this.chatbotState.chatlist.push(msgObj);
        this.scrollToBottom();
        const results = await ChatbotService.postMessage(msgObj);
        this.refreshMessages(results);
        this.userMessage = "";
        this.isLoading = false;
    }

    async sendInfo(message: any) {
        this.suggestionListDisplay = [];
        this.isLoading = true;
        this.scrollToBottom();
        const msgObj = ChatbotService.createMessage({ info: message }, userEnum.user);
        const results = await ChatbotService.postMessage(msgObj);
        this.refreshMessages(results);
        this.isLoading = false;
    }

    closeChat() {
       this.isChatOpen = false;
    }

    getInputElement(elementId: string) {
        let componentElement = document.querySelector('plus-chatbot-component')
        if (componentElement && componentElement.shadowRoot) { // running as if component
            return componentElement.shadowRoot.getElementById(elementId);
        } else {
            return document.getElementById(elementId);
        }
    }

    scrollToBottom() {
        setTimeout(function () {
            let componentElement = document.querySelector('plus-chatbot-component')
            if (componentElement && componentElement.shadowRoot) { // running as if component
                let chatbotContentElement = componentElement.shadowRoot.querySelector('.chatbot-content');
                if (chatbotContentElement) chatbotContentElement.scrollTop = 99999999
            } else { //if not running as component
                let someElement = document.querySelector('.chatbot-content');
                if (someElement) someElement.scrollTop = 99999999
            }
        }, 200)
    }

    sendMessage(message: string) {
        //let msg = message.charAt(0).toUpperCase() + message.slice(1)
        let msg = message;
        this.suggestionListDisplay = []
        let trimMessage = msg.trim();
        if (trimMessage.length == 0) return;
        this.sendReply(trimMessage);
        this.userMessage = "";
    }

    sendSuggestion(message: string) {
        this.suggestionListDisplay = [];
        this.sendReply(message)
        this.userMessage = "";
    }

    suggestionMessage(e: any) {
        if (e.keyCode == 13) return;
        let textInputElement = this.getInputElement('SendTextPanel');
        let message = textInputElement ? (<HTMLInputElement>textInputElement).value : "";
      
        if (message.trim().length <=1) {
            this.suggestionListDisplay = [];
            return;
        }
      
        if (this.suggestionList.length > 0) {
            this.suggestionListDisplay = this.suggestionList.filter((suggestion) => {
                if (suggestion.keywords.toLowerCase().indexOf(message.toLowerCase()) != -1)
                {  return suggestion; }
            }).map((toll)=>toll.text);
        } else {
            this.suggestionListDisplay = [];
        }
    }

    shareLocation() {
        if (!navigator.geolocation) {
            this.sendInfo("GeoLocationBlockedByUser");
            return false;
        }
        this.isLoading = true;
        const options = { enableHighAccuracy: true, maximumAge: 0 }
        navigator.geolocation.getCurrentPosition(
            async (result) => {
                this.isLoading = false;

                this.currentlocation = `${result.coords.latitude.toFixed(7)},${result.coords.longitude.toFixed(7)}`;
                this.sendInfo(this.currentlocation)
            },
            (error) => {
                if (error) this.sendInfo("GeoLocationBlockedByUser")
            },
            options);
    }


    makeCall() {
        window.open(`tel:1-800-88-0000`);
        this.sendReply(`Call PLUSLine now! 1-800-88-0000`);
    }

    async sendSos() {
        await this.sendReply(`SOS!`)
    }

    readMore(index: number) {
        this.readMoreIndex.push(index);
    }

    async openChat() {
        this.isChatOpen = true;
        this.isLoading = true;
        if (this.chatbotState.chatlist.length === 0) {
        const results = await ChatbotService.setupChatSession();
        this.refreshMessages(results);
        } else {
            this.scrollToBottom();
        }
        this.isLoading = false;

        
        this.suggestionList = await chatbotService.getAutoCompleteStrings();
        this.tollPlazaSuggestionList = await ChatbotService.getTollPlazaSuggestion();
        
    }
}
